import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import {DataContext} from '../Context/contextApi'

const Dashboard = () => {
  const { t } = useTranslation();
  const { userProfile } = useContext(DataContext);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <div id="panel-1" className="panel" style={{ height: "160px" }}>
            <div className="panel-hdr">
              <h2 className="h2">{t("global.user")}</h2>
            </div>
            <div className="panel-container">
              <div className="panel-content">
                <div className="d-inline-flex  m-0">
                  {userProfile.imgProfile === null ||
                  userProfile.imgProfile === "" ? (
                    <img
                      src="/img/demo/avatars/avatar-m.png"
                      className="profile-image-md rounded-circle"
                      alt=""
                      style={{ width: "64px", height: "64px" }}
                    />
                  ) : (
                    <img
                      src={userProfile.imgProfile}
                      className="profile-image-md rounded-circle"
                      alt=""
                      style={{ width: "64px", height: "64px" }}
                    />
                  )}
                  <div className="d-block ml-3">
                    <h3>{userProfile.name}</h3>
                    <h4>{userProfile.email}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <div id="panel-2" className="panel" style={{ height: "160px" }}>
            <div className="panel-hdr">
              <h2 className="h2">{t("global.company")}</h2>
            </div>
            <div className="panel-container">
              <div className="panel-content">
                <div className="d-inline-flex  m-0">
                  <div className="d-block ml-3">
                    {userProfile.billing_name === "" &&
                    userProfile.billing_direction === "" &&
                    userProfile.billing_cuit === 0 ? (
                      <h3>No existen datos ingresados</h3>
                    ) : (
                      <>
                        <h3>{userProfile.billing_name}</h3>
                        <h4>
                          {userProfile.billing_cuit === 0
                            ? ""
                            : userProfile.billing_cuit}
                        </h4>
                        <span>{userProfile.billing_direction}</span>
                      </>
                    )}
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <div id="panel-3" className="panel">
            <div className="panel-hdr">
              <h2>{t("dashboard.quantityUser")}</h2>
            </div>
            <div className="panel-container">
              <div className="panel-content">
                <div className="d-flex justify-content-center">
                  <h1 className="h1">{userProfile.TotalAccounts}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <div id="panel-4" className="panel">
            <div className="panel-hdr">
              <h2>{t("dashboard.diskSpace")}</h2>
            </div>
            <div className="panel-container">
              <div className="panel-content">
                <div className="d-flex justify-content-center">
                  <h1 className="h1">{userProfile.DiskSpace}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Dashboard;

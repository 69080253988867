import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyBQn16uRgGXy-H7nz-yswX60xo5R8nFhts",
  authDomain: "api-qr.firebaseapp.com",
  projectId: "api-qr",
  storageBucket: "api-qr.appspot.com",
  messagingSenderId: "912975539184",
  appId: "1:912975539184:web:f0548cd5980f3251c88b35",
  measurementId: "G-XDBHG12P0C"
  };

// require('firebase/analytics')
// require('firebase/storage')

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// const analytics = firebase.analytics();
// const storage = firebase.storage();
//console.log('analytics',analytics)
//console.log('storage',storage)

const app = initializeApp(firebaseConfig);
const auth = firebase.auth()
const db = getFirestore();

// const provider = new firebase.auth.GoogleAuthProvider()

// export {auth}

export {app, db, auth};
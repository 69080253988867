import React, { createContext, useState } from "react";
// import { v4 as uuidv4 } from 'uuid';
import { db } from "../firebase";
// import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import PropTypes from "prop-types";
import { UserAccount } from "../Models/UserAccount"

// import { getAuth, signInAnonymously } from "firebase/auth";

export const DataContext = createContext();
const auth = getAuth();
// const tenantId = "app-apiqr-9j912";
// auth.tenantId = tenantId;


const DataProvider = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  // const dataUsuario = { uid: null, email: null, estado: null, perfilVerificado: null};
  const dataUsuario = new UserAccount();  
  const [usuario, setUsuario] = useState(dataUsuario);
  const [userProfile, setUserProfile] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [editAccount, setEditAccount] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(false);

  var unsuscribe1 = null;

  
  const detectarDocumentoUsuario = async () => {
    // console.log('usuario',usuario) 

    if (usuario == null || usuario.uid == null) return;

    try {
      const docRef = doc(db, "users", usuario.uid);
      const docSnap = await getDoc(docRef);
      // console.log('docSnap', docSnap)
      if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data());
        setUserProfile(docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        //console.log("No such document!");
        setUserProfile(false);
      }
      setPageLoaded(true);
    } catch { }
  };
 
  React.useEffect(async () => {  
    detectarDocumentoUsuario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuario]);
  React.useEffect(() => {
    detectarUsuario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const detectarUsuario = React.useCallback(() => {
    // signInAnonymously(auth)
    //   .then(() => {
    //     console.log("test log auth");
    //   })
    //   .catch((error) => {
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     console.log("errorCode", error.code);
    //   });

    unsuscribe1 = onAuthStateChanged(auth, async (user) => {
      if (user) {
        let tokenResult = await user.getIdTokenResult()
        let claims = tokenResult.claims

        let providerPass = claims["firebase"].sign_in_provider
        let perfilVerificado = claims["perfilVerificado"]
        // let providerPass = user.auth.currentUser.providerData[0].providerId
        // setUsuario({ uid: user.uid, estado: true, perfilVerificado: user.perfilVerificado, email: user.email });
        setUsuario({ ...usuario, uid: user.uid, estado: true, perfilVerificado: perfilVerificado, email: user.email , name: user.displayName, imgProfile: user.photoURL, providerPass: providerPass});
        // const uid = user.uid
        

      } else {
        // User is signed out
        setUsuario({ ...usuario, uid: null, estado: false, perfilVerificado: null, email: "", name: "", imgProfile: "", providerPass: ""});
        //console.log("usuario estado false", usuario.estado);
      }

    });
  }, []);
  
  const logInWithEmailAndPassword = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user; // eslint-disable-line
        // console.log("test user login",user)
        // ...
      })
      .catch((error) => {
        console.log(error.code);
        console.log(error.message);
      });
  };

  // const cerrarSesion = () => {
  //   // console.log("pasa")
  //   // auth.signOut();
  //   // console.log("pasa2")
  //   signOut(auth).then(() => {
  //     // Sign-out successful.
  //   }).catch((error) => {
  //     console.log(error)s
  //   });
  // };

  const cerrarSesion = React.useCallback(() => {
    try {
      //no recibir los eventos de firebase auth
      unsuscribe1()
    }
    catch { }


    auth.signOut();
    window.location.href = "https://api-qr.com/";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  React.useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      setIsMobile(true);
      // console.log(isMobile)
    } else {
      // false for not mobile device
      setIsMobile(false);
      // console.log(isMobile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  // const ingresoUsuario = async () => {
  //     try {
  //         await auth.signInWithPopup(provider);
  //     } catch (error) {
  //         // console.log(error);
  //     }
  // };

  // const cerrarSesion = () => {
  //     auth.signOut();
  // };

  // const [isUserNull,setIsUserNull] = useState(false)
  // React.useEffect(() => {
  //   if (userProfile == null) {
  //     const timer = setTimeout(() => {
  //       setIsUserNull(false);
  //     }, 3000);
  //     return () => clearTimeout(timer);
  //   }else{
  //     setIsUserNull(true)
  //   }
  //   console.log(isUserNull  )
  // }, [userProfile]);
 
  return (
    <DataContext.Provider
      value={{
        // isUserNull,
        // setIsUserNull,
        pageLoaded,
        isMobile,
        setIsMobile,
        usuario,
        setUsuario,
        logInWithEmailAndPassword,
        cerrarSesion,
        userProfile,
        detectarDocumentoUsuario,
        setUserProfile,
        isEdit,
        setIsEdit,
        editAccount,
        setEditAccount,
        detectarUsuario
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};
DataProvider.propTypes = {
  children: PropTypes.node,
};
export default DataProvider;

/* eslint-disable react/no-unescaped-entities */

import React, { Fragment, useState, useContext, useCallback, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./EnlacesLogin.css";
import { useTranslation } from "react-i18next";
import ModalEditUser from "./ModalEditUser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataContext } from "../Context/contextApi";

toast.configure();

const EnlacesLogin = ({ authenticated }) => {
  const { cerrarSesion, userProfile } = useContext(DataContext);

  //Traduccion
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  let select = JSON.parse(localStorage.getItem("select"));

  if (!select) {
    select = "";
  }
  const [value, setValue] = useState(select);

  const handleChangeLenguaje = useCallback((e) => { 

    if (e.target.value === "espanol") {
      changeLanguage("Es");
      setValue("espanol");
    } else {
      setValue("ingles");
      changeLanguage("En");
    }
    localStorage.setItem("select", JSON.stringify(e.target.value));
  },[value]);

  const [modalEditar, setModalEditar] = useState(false);
  const [user, setUser] = useState({
    id: "19",
    name: "New user",
    email: "userTest@gmail.com",
    phone: "3584654987",
  });

  // const editarUsuario = () => {
  //   setModalEditar(true);
  // };

  const onHandleSave = (usuario) => {
    setUser(usuario);
    toast.success(`usuario ${usuario.name} editado`);
    setModalEditar(false);
  };

  const onHandleClose = () => {
    setModalEditar(false);
  };
  // Prevents menu from closing when clicked inside
  useEffect(() => {
   var dropdownProfile = document.querySelector(".dropdown-menu");
   var dropdownSelectProfile = document.querySelector(".selectDropProfile");
   if (dropdownProfile == null) {
     return;
   } else {
    dropdownSelectProfile.addEventListener("click", function(event) {
      // alert("click outside");
      event.stopPropagation();
    });
     window.onclick = function(event) {
       if (!event.target.matches(".header-icon")) {
         var dropdowns = document.getElementsByClassName("dropdown-menu");
         
         var i;
         for (i = 0; i < dropdowns.length; i++) {
           var openDropdown = dropdowns[i];
           if (openDropdown.classList.contains("show")) {
             openDropdown.classList.remove("show");
             event.stopropagation();
           }
         }
       }
     };
   }


 }, []);


  // Closes the menu in the event of outside click


  //console.log("enlacesProfile", userProfile)

  return (
    <Fragment>
      {authenticated && userProfile ? (
        <>
          {/*DROPDOWN App*/}
          <span className="h4 m-0">api-qr</span>
          <div className="navbar ml-auto">
            {/*Seccion USUARIO*/}
            <div>
              <div
                data-toggle="dropdown"
                // title={userProfile.displayName}
                className="header-icon header-icon-flex"
                aria-expanded="false"
              >
                {userProfile.imgProfile === null || userProfile.imgProfile === ""? (
                  <img
                    src="/img/demo/avatars/avatar-m.png"
                    className="profile-image-md rounded-circle cursor-pointer"
                    alt=""
                  />
                ) : (
                  <img
                    src={userProfile.imgProfile}
                    className="profile-image-md rounded-circle cursor-pointer"
                    alt=""
                  />
                )}
              </div>

              <div className="dropdown-menu dropdown-menu-animated dropdown-lg">
                <div className="dropdown-header bg-trans-gradient d-flex flex-row py-4 rounded-top">
                  <div className="d-flex flex-row align-items-center mt-1 mb-1 color-white">
                    <span className="mr-2">
                      {userProfile.imgProfile === null  || userProfile.imgProfile === "" ? (
                        <img
                          src="/img/demo/avatars/avatar-m.png"
                          className="profile-image rounded-circle"
                          alt=""
                        />
                      ) : userProfile.imgProfile !== null || userProfile.imgProfile !== ""? (
                        <img
                          src={userProfile.imgProfile}
                          className="rounded-circle profile-image"
                          alt=""
                        />
                      ) : (
                        <img
                          src="img/demo/avatars/avatar-m.png"
                          className="profile-image rounded-circle"
                          alt=""
                        />
                      )}
                    </span>
                    <div className="info-card-text">
                      {userProfile.name == null ? (
                        <span className="text-truncate text-truncate-md opacity-80">
                          {user.name}
                        </span>
                      ) : (
                        <div className="fs-lg text-truncate text-truncate-lg">
                          {userProfile.name}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <a
                  className="dropdown-item fw-500 py-3"
                  // onClick={editarUsuario}
                  href="/profile"
                  to="/profile"
                >
                  {t("global.profile")}
                </a>
                <div className="dropdown-divider m-0"></div>
                <div
                  id="idiomaEstilo"
                  className="dropdown-lang py-3 px-4 hover-white"
                >
                  <strong className="fw-500">{t("global.languages")}</strong>
                  <select
                    className="custom-select adjust-select mt-2 selectDropProfile"
                    value={value}
                    onChange={handleChangeLenguaje}
                  >
                    <option className="select2-results__option" value="espanol">
                      {t("global.spanish")}
                    </option>
                    <option className="select2-results__option" value="ingles">
                      {t("global.english")}
                    </option>
                  </select>
                </div>
                {/*<SelectorIdiomas />*/}
                <div className="dropdown-divider m-0"></div>

                <div
                  className="dropdown-item fw-500 py-3"
                  // href="/signin"
                  onClick={cerrarSesion}
                >
                  {t("global.logout")}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>
          <NavLink to="auth">
            <button color="inherit">{t("global.login")}</button>
          </NavLink>
        </div>
      )}

      {modalEditar ? (
        <ModalEditUser
          user={user}
          setUser={setUser}
          onHandleSave={onHandleSave}
          onHandleClose={onHandleClose}
        />
      ) : null}
    </Fragment>
  );
};;

export default EnlacesLogin;

import React, { useState, useContext, useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Container from "../../Container";
import { Spinner } from "react-bootstrap";

import { APICuentas } from "../../../../functions/ApiClient";
import { WaAccount } from "../../../../Models/WaAccount";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../../../Context/contextApi";
import { toast } from "react-toastify";

const Main = () => {
  const { t } = useTranslation();

  const mock = [
    {
      title: t("pricing.starter"),
      subtitle: t("pricing.accessAllFeatures"),
      price: { monthly: "$22", annual: "$190" },
      features: [
        t("pricing.accountLineNumber"),
        t("pricing.messageOnlyText"),
        t("pricing.multimediaMessage"),
        t("pricing.webhookNotification"),
        t("pricing.5daysTrial"),
        t("pricing.requiresCreditCard"),
      ],
      isHighlighted: false,
    },
  ];
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [pricingOption, setPricingOption] = useState("monthly");

  const handleClick = (event, newPricingOption) => {
    if (newPricingOption !== null) {
      setPricingOption(newPricingOption);
    }
  };

  let navigate = useNavigate();
  let [buttonDisabled, setButtonDisable] = useState(false);

  const { userProfile } = useContext(DataContext); // eslint-disable-line
  const date = new Date(); // eslint-disable-line
  const addNewAccount = useCallback(async () => {
    setButtonDisable(true);
    let newAccount = new WaAccount();
    newAccount.name = "";

    try {
      let result = await APICuentas.añadirCuenta(newAccount); // eslint-disable-line
      //console.log("resultApi", result);

      toast.success(t("tostify.accountCreated"));
      setButtonDisable(false);
      navigate("/accounts");
    } catch (error) {
      console.log("error adding account", error);
      toast.error("error al crear la cuenta");
    }

    // console.log("Document written with ID: ", accountRef.id);

    //*********************** */
    // ===> return to LIST
  }, []);

  const renderToggler = () => (
    <Box display={"flex"} justifyContent={"center"}>
      <ToggleButtonGroup value={pricingOption} exclusive onChange={handleClick}>
        <ToggleButton
          value="monthly"
          size={isMd ? "large" : "small"}
          sx={{
            backgroundColor:
              pricingOption === "monthly"
                ? `${theme.palette.primary.light} !important`
                : "transparent",
            border: "1px solid #008dff",
            width: "100px",
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              color:
                pricingOption !== "annual" ? "common.white" : "text.primary",
            }}
          >
            {t("pricing.monthly")}
          </Typography>
        </ToggleButton>
        <ToggleButton
          value="annual"
          size={isMd ? "large" : "small"}
          sx={{
            backgroundColor:
              pricingOption === "annual"
                ? `${theme.palette.primary.light} !important`
                : "transparent",
            border: "1px solid #008dff",
            width: "100px",
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              color:
                pricingOption === "annual" ? "common.white" : "text.primary",
            }}
          >
            {t("pricing.annual")}
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );

  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          // backgroundColor: 'rgb(70, 125, 227) !important',
          // backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
          // marginTop: -13,
          // paddingTop: 13,
        }}
      >
        <Container
          position={"relative"}
          zIndex={3}
          style={{ padding: "unset", paddingTop: 30 }}
        >
          <Box>{renderToggler()}</Box>
        </Container>
      </Box>
      <Container>
        <Grid
          container
          spacing={1}
          style={{
            justifyContent: "space-evenly",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {mock.map((item, i) => (
            <Grid item xs={12} md={6} key={i}>
              <Box
                component={Card}
                height={1}
                display={"flex"}
                flexDirection={"column"}
                variant={"outlined"}
              >
                <CardContent
                  sx={{
                    padding: 4,
                    minHeight: "500px",
                  }}
                >
                  <Box marginBottom={2}>
                    <Typography variant={"h4"} fontWeight={600} gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography color={"text.secondary"}>
                      {item.subtitle}
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"baseline"}
                    marginBottom={2}
                  >
                    <Typography variant={"h3"} fontWeight={700}>
                      {pricingOption === "annual"
                        ? item.price.annual
                        : item.price.monthly}
                    </Typography>
                    <Typography
                      variant={"subtitle1"}
                      color={"text.secondary"}
                      fontWeight={700}
                    >
                      {pricingOption === "annual" ? "/y" : "/mo"}
                    </Typography>
                  </Box>
                  <Grid container spacing={1}>
                    {item.features.map((feature, j) => (
                      <Grid item xs={12} key={j}>
                        <Box
                          component={ListItem}
                          disableGutters
                          width={"auto"}
                          padding={0}
                        >
                          <Box
                            component={ListItemAvatar}
                            minWidth={"auto !important"}
                            marginRight={2}
                          >
                            <Box
                              component={Avatar}
                              bgcolor={theme.palette.primary.main}
                              width={20}
                              height={20}
                            >
                              <svg
                                width={12}
                                height={12}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </Box>
                          </Box>
                          <ListItemText primary={feature} />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
                <Box sx={{padding: "20px", alignSelf: "center"}}>
                  <Button
                    onClick={addNewAccount}
                    variant="contained"
                    disabled={buttonDisabled === true ? true : false}
                    startIcon={
                      buttonDisabled === true ? (
                        <Spinner
                          style={{
                            height: "1.5rem",
                            width: "1.5rem",
                            fontSize: "12px",
                          }}
                          animation="border"
                        />
                      ) : null
                    }
                  >
                    {buttonDisabled === false
                      ? t("global.create")
                      : t("global.creating")}
                  </Button>
                </Box>
              </Box>
            </Grid>
          ))}
          <Typography
            align={"center"}
            variant={"caption"}
            color="text.secondary"
            component={"p"}
            style={{ marginTop: "30px" }}
          >
            *{t("pricing.trialAdvice")}
          </Typography>
        </Grid>
      </Container>
    </Box>
  );
};

export default Main;

import React, { useContext, useState } from "react";
// import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import TextField from "@mui/material/TextField";
import { DataContext } from "../../../Context/contextApi";
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from "react-i18next";
import { APIUserAccount } from "../../../functions/ApiClient";
import { Spinner } from "react-bootstrap";

import { getAuth } from "firebase/auth";

// { onClose, open }
const PopupNullUserData = () => {
  const { t } = useTranslation();

  const { usuario, detectarDocumentoUsuario, detectarUsuario } = useContext(DataContext);
  const auth = getAuth();
  console.log("auth", auth);
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  // console.log(usuario)
  const [isComplete, setIsComplete] = useState(false);
  // const obtenerDatos = async () => {
  //   array = [];
  //   array.push(userName.value);
  //   console.log(array);
  //   console.log("newUser",newUser)
  //   let result = await APIUserAccount.inicializarUsuario();
  //   console.log("resultApi", result)

  // };

  const obtenerDatos = async () => {
    try {
      const newName = document.querySelector("#userName").value;
      usuario.name = newName;
      // const accountRef = await addDoc(collection(db, "users"), {
      //   name: newName,
      //   mail: usuario.email,
      //   apiKey: "",
      //   imgProfile: "",
      //   billing_name: "",
      //   billing_direction: "",
      //   billing_cuit: 0,
      // });
      setIsComplete(true);
      let result = await APIUserAccount.inicializarUsuario(usuario); // eslint-disable-line
      await auth.currentUser.getIdToken(true);

      // toast.success(t("tostify.changesSaved"));
    } catch (error) {
      setIsComplete(false);
      console.log("ERROR", error);
    }
    detectarDocumentoUsuario();
    detectarUsuario();
  };

  return (
    <Dialog
      // onClose={onClose}
      // fullScreen={fullScreen}
      open={true}
      // maxWidth={"sm"}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 4,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingY: { xs: 4, sm: 10 },
          paddingX: { xs: 4, sm: 12 },
          height: "500px",
          // minWidth: "500px",
        }}
      >
        <Typography
          variant={"h5"}
          fontWeight={700}
          align={"center"}
          marginBottom={2}
        >
          {t("global.userInfo")}
        </Typography>
        <Typography
          variant={"h6"}
          component={"p"}
          fontWeight={100}
          align={"center"}
          fontSize={"1rem"}
        >
          {t("popupUserNull.subtitle")}
        </Typography>
        <Grid container spacing={2} sx={{ marginY: 4 }}>
          <Grid item xs={12}>
            <Typography fontWeight={700}>Email</Typography>
            <Typography fontWeight={100} sx={{ marginBottom: 4 }}>
              {usuario.email}
            </Typography>
            <Typography fontWeight={700} sx={{ marginBottom: 2 }}>
              {t("global.name")} &#40;{t("global.optional")}&#41;
            </Typography>
            <TextField
              label={t("global.enterName")}
              id="userName"
              variant="outlined"
              name={"name"}
              defaultValue={usuario.name !== "" ? usuario.name : ""}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{ marginY: 4, position: "absolute", bottom: 0, right: "40px " }}
        >
          <Grid item xs={12} style={{ textAlign: "end" }}>
            <Button
              size={"large"}
              variant={"contained"}
              type={"submit"}
              color={"success"}
              // fullWidth
              startIcon={
                isComplete === true ? (
                  <Spinner
                    style={{
                      height: "1.5rem",
                      width: "1.5rem",
                      fontSize: "12px",
                    }}
                    animation="border"
                  />
                ) : (
                  <CheckIcon />
                )
              }
              onClick={obtenerDatos}
              disabled={isComplete === true ? true : false}
            >
              {}
              {isComplete === true ? t("global.ending") : t("global.finalize")}
            </Button>
            {/* <Spinner animation="border">
            </Spinner> */}
          </Grid>
        </Grid>
        {/* <Typography
          align={'center'}
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          // onClick={onClose}
        >
          cerrar sesion
        </Typography> */}
      </Box>
    </Dialog>
  );
};
// PopupBox.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
// };
export default PopupNullUserData;

/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import GoogleButton from "react-google-button";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import  provider  from '../../../../firebase';
import { toast } from "react-toastify";



const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  password: yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have at minimum length of 8"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const Form = () => {
  const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    "& > :not(style) + :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));

 
  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: validationSchema,
  //   onSubmit,
  // });
  const auth = getAuth();
  const { t } = useTranslation();

  const [errorPass,setError] = useState(null); //eslint-disable-line
 

  // const procesarDatos = (e) => {
  //   e.preventDefault();
  //   if (!email.trim() || !pass.trim()) {
  //     console.log("Datos vacíos email!");
  //     // setError("Datos vacíos email!");
  //     return;
  //   }
  //   if (!pass.trim()) {
  //     console.log("Datos vacíos pass!");
  //     // setError("Datos vacíos pass!");
  //     return;
  //   }
  //   if (pass.length < 6) {
  //     console.log("6 o más carácteres");
  //     // setError("6 o más carácteres en pass");
  //     return;
  //   }
  //   console.log("correcto...");
  //   setError(null);
  //   login();
  // };
 
  const initialValues = {
    email: '',
    password: '',
    
  };
  const provider = new GoogleAuthProvider();
  const ingresarUsuarioGoogle = async () => {
    signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken; // eslint-disable-line
      // The signed-in user info.
      const user = result.user; // eslint-disable-line
        // eslint-disable-next-line react-hooks/exhaustive-deps

      // ...
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code; // eslint-disable-line
      const errorMessage = error.message; // eslint-disable-line
      // The email of the user's account used.
      const email = error.email; // eslint-disable-line
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error); // eslint-disable-line

      // ...
    });
  }
  const onSubmit = (values) => {
    //console.log(values)
    // alert(JSON.stringify(values, null, 2));

    return values;
  };
  
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
  
    onSubmit,
  });
    let history = useNavigate();
 

  const login = React.useCallback(async () => {

    try {
      const res = await signInWithEmailAndPassword(auth, formik.values.email, formik.values.password); // eslint-disable-line
      //console.log("dentro")
      history("/", { replace: true })
      

    } catch (error) {
      //console.log(error);
      if (error.code === "auth/invalid-email") {
        setError(t("login.errorMail"));
        // alert(t("login.errorMail"))
      }
      if (error.code === "auth/user-not-found") {
        setError("Email no registrado");
        // alert(t("Email no registrado"))

      }
      if (error.code === "auth/wrong-password") {
        setError(t("login.errorPassword"));
        // alert(t("login.errorPassword"))

      }
      toast.error(t("tostify.invalidUserPass"));
    }
  }, [formik.values.email, formik.values.password]);



 

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"text.secondary"}
        >
          {t("global.login")}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          {t("login.welcomeBack")}
        </Typography>
        <Typography color="text.secondary">
          {t("login.loginSubtitle")}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              {t("login.enterMail")}
            </Typography>
            <TextField
              label="Email *"
              variant="outlined"
              name={"email"}
              fullWidth
              // onChange={(e) => setEmail(e.target.value)}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              marginBottom={2}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={"subtitle2"}>
                  {t("login.enterPassword")}
                </Typography>
              </Box>
              <Typography variant={"subtitle2"}>
                <Link
                  component={"a"}
                  color={"primary"}
                  href={"/passwordReset"}
                  underline={"none"}
                  to={"/passwordReset"}
                >
                  {t("login.forgotPassword")}
                </Link>
              </Typography>
            </Box>
            <TextField
              label={t("global.password") + "*"}
              variant="outlined"
              name={"password"}
              type={"password"}
              fullWidth
              // onChange={(e) => setPass(e.target.value)}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Grid item container xs={12}>
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "stretched", sm: "center" }}
                justifyContent={"flex-end"}
                width={1}
                maxWidth={600}
                margin={"0 auto"}
                marginTop={2}
              >
                <Button
                  size={"large"}
                  variant="contained"
                  type="submit"
                  onClick={login}
                  // disabled={
                  //   formik.values.password === "" ||
                  //   formik.values.email === "" ||
                  //   Boolean(formik.errors.password) ||
                  //   Boolean(formik.errors.email)
                  // }
                >
                  {t("global.login")}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              maxWidth={600}
              margin={"0 auto"}
            >
              <Root>
                <Divider style={{ textTransform: "uppercase" }}>
                  {t("global.or")}
                </Divider>
              </Root>
            </Box>
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"center"}
              width={1}
              maxWidth={600}
              margin={"0 auto"}
            >
              <GoogleButton
                label={t("login.signinGoogle")}
                onClick={ingresarUsuarioGoogle}
                to="/"
              />
            </Box>
          </Grid>

          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              maxWidth={600}
              margin={"0 auto"}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={"subtitle2"}>
                  {t("login.haveAccountYet")}{" "}
                  <Link
                    component={"a"}
                    color={"primary"}
                    href={"/signup"}
                    underline={"none"}
                    to="/signup"
                  >
                    {t("login.signUpHere")}
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;

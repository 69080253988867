import React from "react";
import NavMenu from "./NavMenu";
import EnlacesLogin from "./EnlacesLogin";
import "./MainLayout.css";
import PropTypes from 'prop-types';



function MainLayoutUnverify(props) {

  
  return (
    <div className="page-wrapper">
      <div className="page-inner">
        <div className="page-sidebar sidebar-sticky disabled" style={{zIndex: 0}}>
          <NavMenu />
        </div>
        <div className="page-content-wrapper">
          <div className="page-header header-sticky disabled">
            <EnlacesLogin authenticated={true} />
          </div>
          <div id="js-page-content" className="page-content">
              {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}
MainLayoutUnverify.propTypes = {
  children: PropTypes.node,
};
export default MainLayoutUnverify;

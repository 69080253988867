class UserAccount { 
    uid: string;
    perfilVerificado: boolean;
    estado?: boolean;
    name: string;
    email: string;
    apiKey: string;
    imgProfile: string;
    billing_name: string;
    billing_direction: string;
    billing_cuit: number;
    providerPass: string;
    pendingDelete: boolean;
    TotalAccounts: number;
    DiskSpace: string;


    constructor(json: any) {
        if (json) {
            this.name = json.name ? json.name : ""
            this.email = json.email ? json.email : ""
            this.apiKey = json.apiKey ? json.apiKey : ""
            this.imgProfile = json.imgProfile ? json.imgProfile : "avatar-m.png"
            this.billing_name = json.billing_name ? json.billing_name : ""
            this.billing_direction = json.billing_direction ? json.billing_direction : ""
            this.billing_cuit = json.billing_cuit ? json.billing_cuit : 0;
            this.uid = json.uid ? json.uid : ""
            this.perfilVerificado = json.perfilVerificado ? json.perfilVerificado : false
            this.estado = json.estado ? json.estado : false
            this.providerPass = json.providerPass ? json.providerPass : ""
            this.pendingDelete = json.pendingDelete ? json.pendingDelete : false
            this.TotalAccounts = json.TotalAccounts ? json.TotalAccounts : 0
            this.DiskSpace = json.DiskSpace ? json.DiskSpace : ""
        } else {
            this.name = ""
            this.email = ""
            this.apiKey = ""
            this.imgProfile = "avatar-m.png"
            this.billing_name = ""
            this.billing_direction = ""
            this.billing_cuit = 0
            this.uid = ""
            this.perfilVerificado = false
            this.estado = undefined
            this.providerPass = ""
            this.pendingDelete = false
            this.TotalAccounts = 0
            this.DiskSpace = ""
        }
    }
};

export { UserAccount }
import React, {useState, useEffect, useRef} from 'react'
import { useTranslation } from 'react-i18next'
import BoxInfoState from '../utils/boxInfoState'
import LayoutCentered from '../layoutCentered'
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
// import {DataContext} from '../../Context/contextApi'
import { NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import EditAccount from './EditAccount';
import { WaAccount } from '../../Models/WaAccount';
import { APICuentas } from "../../functions/ApiClient";
import Spinner from '../Spinner';
import {waAccountEdit, setCuentaWsp} from "../../ReduxToolkit/features/WaAccountSlice"


import { useDispatch, useSelector } from "react-redux";
// toast.configure()

const Accounts = () => {
  const { t } = useTranslation();
  // const { editAccount, setEditAccount } = useContext(DataContext);
  const [collectionAccounts, setCollectionAccounts] = useState([])
  let [chargingSpinner, setChargingSpinner] = useState(false)
  const mountedRef = useRef(true)


  const CuentaWsp = useSelector(waAccountEdit) 
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setCuentaWsp(CuentaWsp));   
  }, [])
  useEffect(async () => {
   try{
    if (!mountedRef.current) return null

    setChargingSpinner(true)
    let result = await APICuentas.getCuentas();
    //console.log("resultApi", result);

    let array = [];
    result.data.forEach((json) => {
      let jsonWa = new WaAccount(json);
      array.push(jsonWa);
    });
    // const accountRef = collection(db, "users", userProfile.uid, "accounts");

    // const querySnapshot = await getDocs(accountRef);
    // querySnapshot.forEach((doc) => {

    //   let json = doc.data()
    //   json.id = doc.id
    //   let jsonWa = new WaAccount(json)
    //   array.push(jsonWa)
    // })
    setCollectionAccounts(array);

    setChargingSpinner(false)
   }catch(error){
     console.log("error charging list", error)
   }
  }, [CuentaWsp]);
  useEffect(() => {
    return () => { 
      mountedRef.current = false
    }
  }, [])
  
  return (
    <>
      {Object.keys(CuentaWsp).length === 0 ? (
        <>
          <div className="content">
            <h2 className="text-center m-3 h1">{t("global.myAccounts")}</h2>
          </div>
          <LayoutCentered>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <NavLink to="/accounts/addAccount">
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                >
                  {t("global.addAccount")}
                </Button>
              </NavLink>
            </div>
            {chargingSpinner === false ? (
              collectionAccounts.map((item, i) => (
                <BoxInfoState key={i} account={item} />
              ))
            ) : (
              <Spinner style={{top: "35vh"}}></Spinner>
            )}
          </LayoutCentered>
        </>
      ) : (
        <>
          <EditAccount></EditAccount>
        </>
      )}
    </>
  );
};
export default Accounts;

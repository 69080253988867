import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";


const Headline = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        sx={{
          textTransform: 'uppercase',
          fontWeight: 'medium',
        }}
        gutterBottom
        color={'textSecondary'}
        align={'center'}
      >
        F.A.Q.
      </Typography>
      <Typography variant="h3" align={'center'} fontWeight={700} gutterBottom>        
        {t("ApiDocs.faqTitle")}
      </Typography>
      <Typography variant="h6" align={'center'} color={'textSecondary'}>
        {t("ApiDocs.faqSubtitle")}
      </Typography>
    </Box>
  );
};

export default Headline;

import React from "react";
// import { Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

// import TextField from "@material-ui/core/TextField";
import LayoutCentered from "../layoutCentered";

import Pricing from "../Pricing/Pricing";
// import { useTheme } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";

// import {Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';
// import {PaymentElement} from '@stripe/react-stripe-js';

// const stripePromise = loadStripe('pk_test_51KmevPEubz4xmWeg5JTN1IbKSj7pl08gpmzYc3AwDxG3U0Cr3WDpm4pr05Kr5qBoKliZV7CHWWiQ1mn4SxLjZThf00PqYKuJls');

// const ProductDisplay = () => (
//   <section>
//     <div className="product">
//       <Logo />
//       <div className="description">
//         <h3>Starter plan</h3>
//         <h5>$20.00 / month</h5>
//       </div>
//     </div>
//     <form action="/create-checkout-session" method="POST">
//       {/* Add a hidden field with the lookup_key of your Price */}
//       <input type="hidden" name="lookup_key" value="qr1" />
//       <button id="checkout-and-portal-button" type="submit">
//         Checkout
//       </button>
//     </form>
//   </section>
// );

// const SuccessDisplay = ({ sessionId }) => {
//   return (
//     <section>
//       <div className="product Box-root">
//         <Logo />
//         <div className="description Box-root">
//           <h3>Subscription to starter plan successful!</h3>
//         </div>
//       </div>
//       <form action="/create-portal-session" method="POST">
//         <input
//           type="hidden"
//           id="session-id"
//           name="session_id"
//           value={sessionId}
//         />
//         <button id="checkout-and-portal-button" type="submit">
//           Manage your billing information
//         </button>
//       </form>
//     </section>
//   );
// };

// const Message = ({ message }) => (
//   <section>
//     <p>{message}</p>
//   </section>
// );

// const Logo = () => (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     xmlnsXlink="http://www.w3.org/1999/xlink"
//     width="14px"
//     height="16px"
//     viewBox="0 0 14 16"
//     version="1.1"
//   >
//     <defs />
//     <g id="Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
//       <g
//         id="0-Default"
//         transform="translate(-121.000000, -40.000000)"
//         fill="#E184DF"
//       >
//         <path
//           d="M127,50 L126,50 C123.238576,50 121,47.7614237 121,45 C121,42.2385763 123.238576,40 126,40 L135,40 L135,56 L133,56 L133,42 L129,42 L129,56 L127,56 L127,50 Z M127,48 L127,42 L126,42 C124.343146,42 123,43.3431458 123,45 C123,46.6568542 124.343146,48 126,48 L127,48 Z"
//           id="Pilcrow"
//         />
//       </g>
//     </g>
//   </svg>
// );
const AddAccount = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="content">
        <Box marginBottom={4}>
          <Typography
            variant="h3"
            gutterBottom
            align={"center"}
            sx={{
              fontWeight: 900,
            }}
          >
            {t("global.newAccount")}
          </Typography>
          <Typography
            variant="h6"
            component="p"
            color="text.primary"
            align={"center"}
          >
            {t("pricing.description")}
          </Typography>
        </Box>
        <NavLink className="closeAddAccount" to="/accounts">
          <CloseIcon></CloseIcon>
        </NavLink>
      </div>
      <LayoutCentered>
        <div
          className="panel"
          style={{
            marginTop: 25,
          }}
        >
          <Pricing />

        </div>
       
      </LayoutCentered>
    </>
  );
};

export default AddAccount;

import React, { Fragment, useContext } from "react";
import { useTranslation } from 'react-i18next'
import { Button } from 'react-bootstrap';
import "./Billing.css";
import {DataContext} from '../Context/contextApi'




const Billing = () => {
  const { t } = useTranslation();
  const { userProfile } = useContext(DataContext);

  return (
    <Fragment>
      <div className="border-faded border-0 overflow-hidden">
        <div className="page-content p-0">
          <div className="d-flex justify-content-center row">
            <div className="col-lg-7">
              <div className="panel">
                <div className="panel-hdr">
                  <h2 className="h2">{t("billing.amountPayable")}</h2>
                </div>
                <div className="alertPago">¡{t("billing.upToDate")}!</div>
                <div className="alertPagoVencido">
                  <div className="box_payment">
                    <div style={{ fontSize: 20 }}>
                      {t("billing.pendingPayment")}
                    </div>
                    <div>$1000,50</div>
                  </div>
                </div>
              </div>
              <div className="panel">
                <div className="panel-hdr">
                  <h2 className="h2">{t("billing.billingInformation")}</h2>
                  <Button
                    className="btn-xs"
                    variant="outline-secondary"
                    style={{ marginRight: 10 }}
                  >
                    {t("global.edit")}
                  </Button>
                </div>
                <div className="ml-3 pb-2 pt-2">
                  <div className="d-flex">
                    <div className="w-10" style={{ fontWeight: 500 }}>
                      {t("global.name")}
                    </div>
                    <div className="ml-6">{userProfile.billing_name}</div>
                  </div>
                  <div className="d-flex">
                    <div className="w-10" style={{ fontWeight: 500 }}>
                      {t("global.domicile")}
                    </div>
                    <div className="ml-6">{userProfile.billing_direction}</div>
                  </div>
                  <div className="d-flex">
                    <div className="w-10" style={{ fontWeight: 500 }}>
                      Cuit
                    </div>
                    <div className="ml-6">{userProfile.billing_cuit}</div>
                  </div>
                </div>
              </div>
              <div
                className="panel"
                style={{
                  background: "transparent",
                  border: 0,
                  boxShadow: "unset",
                  marginTop: 75,
                }}
              >
                <div className="panel-hdr" style={{ marginBottom: "1.5rem" }}>
                  <h2 className="h2">{t("billing.history")}</h2>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    {/* pendiente de pago */}
                    <div
                      className="panel-inner"
                      style={{ borderLeft: "5px solid red" }}
                    >
                      <div className="panel-container">
                        <div className="panel-content">
                          <div className="stateBillingBox">
                            {/* boton pendiente y saldo */}
                            <div className="m-0" style={{ width: 130 }}>
                              <div className="buttonPendiente">
                                <div className="ml-1">
                                  <i className="fal fa-exclamation-triangle"></i>
                                </div>
                                {/* <div className="lineVertical"></div> */}
                                <span className="spanButton">
                                  {t("billing.pending")}
                                </span>
                              </div>
                              <div className="saldoSize">$1000,50</div>
                            </div>
                            {/* periodo, vencimiento y fecha de pago */}
                            <div className="container">
                              <div className="periodo">
                                <div
                                  className="d-flex"
                                  style={{
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <span className="fw-900">
                                    {t("billing.period")}
                                  </span>

                                  <span className="fw-100">12/11/2021</span>
                                </div>
                              </div>
                              <div className="vencimiento">
                                <div
                                  className="d-flex"
                                  style={{
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <span className="fw-900">
                                    {t("billing.expirationDate")}
                                  </span>

                                  <span className="fw-100">12/11/2021</span>
                                </div>
                              </div>
                              <div className="unset"></div>
                              <div className="fechaPago">
                                <div
                                  className="d-flex mt-2"
                                  style={{
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <span className="fw-900">
                                    {t("billing.paymentDate")}
                                  </span>

                                  <span className="fw-100">12/11/2021</span>
                                </div>
                              </div>
                            </div>

                            {/* boton descargar */}
                            <div>
                              <Button variant="outline-secondary">
                                {t("global.download")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* pagado */}
                    <div
                      className="panel-inner"
                      style={{ borderLeft: "5px solid #1dc9b7" }}
                    >
                      <div className="panel-container">
                        <div className="panel-content">
                          <div className="stateBillingBox">
                            {/* boton pagado y saldo */}
                            {/* <div className="m-0">
                              <div className="buttonPagada">
                                <div>
                                  <i className="fal fa-check"></i>
                                </div>
                                <div className="lineVertical"></div>
                                <span>Pagada</span>
                              </div>
                              <div>$1000,50</div>
                            </div> */}

                            <div className="m-0" style={{ width: 130 }}>
                              <div className="buttonPagada">
                                <div className="ml-1">
                                  <i className="fal fa-check"></i>
                                </div>
                                {/* <div className="lineVertical"></div> */}
                                <span className="spanButton">
                                  {t("billing.paid")}
                                </span>
                              </div>
                              <div className="saldoSize">$12500,50</div>
                            </div>
                            {/* periodo y vencimiento */}

                            <div className="container">
                              <div className="periodo">
                                <div
                                  className="d-flex"
                                  style={{
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <span className="fw-900">
                                    {t("billing.period")}
                                  </span>

                                  <span className="fw-100">12/11/2021</span>
                                </div>
                              </div>
                              <div className="vencimiento">
                                <div
                                  className="d-flex"
                                  style={{
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <span className="fw-900">
                                    {t("billing.expirationDate")}
                                  </span>

                                  <span className="fw-100">12/11/2021</span>
                                </div>
                              </div>
                            </div>

                            {/* boton descargar */}
                            <div>
                              <Button variant="outline-secondary">
                                {t("global.download")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ textAlignLast: "center" }}>
                <Button variant="outline-info">{t("billing.seeMore")}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Billing;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
// import Divider from '@mui/material/Divider';
// import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';

// import Container from './Container.js';
// import Footer from '../Footer/Footer';




const Main = ({ children, colorInvert = false, bgcolor = 'transparent' }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => { // eslint-disable-line
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => { // eslint-disable-line
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar; // eslint-disable-line

  const trigger = useScrollTrigger({ // eslint-disable-line
    disableHysteresis: true,
    threshold: 38,
  });

  return (
    <Box>
      {/* <Box bgcolor={bgcolor} position={'relative'} zIndex={theme.zIndex.appBar}>
        <Container paddingTop={'8px !important'} paddingBottom={'0 !important'}>
          
        </Container>
      </Box>
      <AppBar
        position={'sticky'}
        sx={{
          top: 0,
          backgroundColor: trigger ? theme.palette.background.paper : bgcolor,
        }}
        elevation={trigger ? 1 : 0}
      >
        <Container paddingY={1}>
        
        </Container>
      </AppBar> */}
    
      <main>
        {children}
      </main>
    </Box>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  colorInvert: PropTypes.bool,
  bgcolor: PropTypes.string,
};

export default Main;

import React from "react";
// import logo from "../../../assets/logo.png";
import { NavLink } from "react-router-dom";
import "./NavMenu.css";
//material icons
import ApiIcon from '@mui/icons-material/Api';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PaymentIcon from '@mui/icons-material/Payment';
import AppsIcon from '@mui/icons-material/Apps';



import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { useTranslation } from 'react-i18next'

const NavMenu = props => {
  const {t} = useTranslation()
  return (
    <>
      <div className="page-logo justify-content-center">
        <a href="/dashboard">
          {/* <img src="img/Icons/SVG/Trii.svg" style={{width:48, height:48}} /> */}
        </a>
      </div>

      <ul className="nav flex-column">
        <li className="nav-item d-block d-inline-flex align-items-center justify-content-center position-relative">
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip style={{ margin: 0 }}>Dashboard</Tooltip>}
          >
            <NavLink
              to="/dashboard"
              className="nav-link"
            >
              <div
                className="fal fa-tachometer-alt-fast fa-1-6x"
                aria-hidden="true"
              ></div>
            </NavLink>
          </OverlayTrigger>
        </li>

        <li className="nav-item d-block d-inline-flex align-items-center justify-content-center position-relative">
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip style={{ margin: 0 }}>{t("global.accounts")}</Tooltip>}
          >
            <NavLink
              to="/accounts"
              className="nav-link"
              data-toggle="tooltip"
              data-placement="right"
            >
              <AppsIcon></AppsIcon>
            </NavLink>
          </OverlayTrigger>
        </li>
        <li className="nav-item d-block d-inline-flex align-items-center justify-content-center position-relative">
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip style={{ margin: 0 }}>{t("global.payments")}</Tooltip>}
          >
            <NavLink
              to="/billing"
              className="nav-link"
              data-toggle="tooltip"
              data-placement="right"
            >
              <PaymentIcon></PaymentIcon>
            </NavLink>
          </OverlayTrigger>
        </li>
        <li className="nav-item d-block d-inline-flex align-items-center justify-content-center position-relative">
          <OverlayTrigger placement="right" overlay={<Tooltip style={{ margin: 0 }}>FAQ</Tooltip>}>
            <NavLink
              to="/faq"
              className="nav-link"
              data-toggle="tooltip"
              data-placement="right"
            >
              <HelpOutlineIcon></HelpOutlineIcon>
            </NavLink>
          </OverlayTrigger>
        </li>
        <li className="nav-item d-block d-inline-flex align-items-center justify-content-center position-relative">
          <OverlayTrigger placement="right" overlay={<Tooltip style={{ margin: 0 }}>API</Tooltip>}>
            <NavLink
              to="/docs"
              className="nav-link"
              data-toggle="tooltip"
              data-placement="right"
            >
              <ApiIcon></ApiIcon>
            </NavLink>
          </OverlayTrigger>
        </li>
      </ul>
    </>
  );
};

export default NavMenu;
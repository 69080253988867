import React, { Fragment} from "react";
import { Route, Routes as ReactRoutes} from "react-router-dom";
import { DataContext } from "./Context/contextApi";

/** Components */
import MainLayout from "./components/MainLayout";
import MainLayoutUnverify from "./components/MainLayoutUnverify";
// import Security from "./components/Security";
import Dashboard from "./components/Dashboard";
import Profile from "./components/Profile";
import Accounts from "./components/Accounts/Accounts";
import Billing from "./components/Billing";
import Faq from "./components/Faq";
import AddAccount from "./components/Accounts/AddAccount";
import DocsIframe from "./components/DocsIframe";
// import docsRoutes from './components/docs/routes.js';

// import Login from "./components/loginView";
// import Loading2 from "./components/Spinn er2";
import Spinner from "./components/Spinner";
import SigninSimple from "./components/SigninSimple";
import SignupSimple from "./components/SignupSimple";
import PasswordResetSimple from "./components/PasswordResetSimple";
import {getAuth} from 'firebase/auth'
// import CommonModal from "./components/commonModal";
// import UserNullModal from "./components/Modals/userNullModal"
import PopupUnverify from "./components/utils/popups/PopupUnverify"
import PopupNullUserData from "./components/utils/popups/PopupNullUserData"



class Routes extends React.Component {
  static contextType = DataContext;
  render() {
    const emailVerified = getAuth() && getAuth().currentUser && getAuth().currentUser.emailVerified ? true : false;
    //console.log("emailVerified",emailVerified)
    // const { setUserProfile } = this.context;    
    const { usuario, userProfile } = this.context;
    const userState = usuario.estado;
    // const {detectarDocumentoUsuario} = this.context;
    // console.log("userState", usuario);

    // console.log("userProfile",userProfile)
    // let url = window.location.href;
    // let tokenurl = url.split('=')[1]
    // async function fetchConfig() {
    //   let response = await detectarDocumentoUsuario(tokenurl); // await APIgetConfig(tokenurl);
    //   console.log("response",response);
    //   if (response) {
    //     setUserProfile(response);
    //   }
    // }

  // console.log('response',response)
    // fetchConfig()
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      console.log("👍", "beforeinstallprompt", event);
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.
    });

    return (
      <Fragment>
        {userState === null && <Spinner></Spinner>}

        {/* {userState == false && (<Login></Login>)} */}
        {/* {userState === false && <SigninSimple></SigninSimple>} */}
        {userState === false && (
          <ReactRoutes>
            <Route path="*" element={<SigninSimple />}></Route>
            <Route path="/" element={<SigninSimple />}></Route>
            <Route path="/signin" element={<SigninSimple />}></Route>
            <Route path="/signup" element={<SignupSimple />}></Route>
            <Route
              path="/passwordReset"
              element={<PasswordResetSimple />}
            ></Route>
          </ReactRoutes>
        )}

        {userState === true && (
          <>
            {userProfile == null && (
              <Spinner/>
            )}
            {(userProfile !== null && userProfile !== false) && (
              <MainLayout>
                <ReactRoutes>
                  <Route exact path="/" element={<Dashboard />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/accounts" element={<Accounts />} />
                  <Route path="/billing" element={<Billing />} />
                  <Route path="/faq" element={<Faq />} />
                  <Route path="/accounts/addAccount" element={<AddAccount />} />
                  <Route path="/docs" element={<DocsIframe />} />
                  {/* <Route path="/docs" element={<AuthenticationDocs />}>
                    </Route> */}
                  {/* {docsRoutes.map((item, i) => (
                    <Route key={i} path={item.path} element={item.renderer()} />
                  ))} */}
                </ReactRoutes>
              </MainLayout>
            )}
            {(userProfile === false || usuario.perfilVerificado !== true ) && (
              <MainLayoutUnverify>
                {/* <CommonModal></CommonModal> */}
                {emailVerified === false ? (
                  <PopupUnverify></PopupUnverify>
                ) : (
                  <PopupNullUserData></PopupNullUserData>
                )}
              </MainLayoutUnverify>
            )}
            {/* {pageLoaded === true &&
              userProfile === null &&
              emailVerified === true && (
                <MainLayoutUnverify>
                  <UserNullModal></UserNullModal>
                </MainLayoutUnverify>
              )} */}
            {/* {emailVerified === true && (
              <>
                {isUserNull === false && <Spinner></Spinner>}
                {userProfile == null && (
                  <MainLayoutUnverify>
                    <UserNullModal></UserNullModal>
                  </MainLayoutUnverify>
                )}
              </>
            )} */}
          </>
        )}
      </Fragment>
    );
  }
}

export default Routes;

import React, { useState, useContext, useCallback, useEffect } from "react"; //eslint-disable-line
import LayoutCentered from "../layoutCentered";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { APICuentas } from "../../functions/ApiClient";
import Button from "@mui/material/Button";
import { DataContext } from "../../Context/contextApi";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
// import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Divider, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import ReplayIcon from "@mui/icons-material/Replay";
import { WaAccount } from "../../Models/WaAccount";

import { useDispatch, useSelector } from "react-redux";
import {
  setCuentaWsp,
  waAccountEdit,
  closeCuentaWsp,
} from "../../ReduxToolkit/features/WaAccountSlice";

// import { WaAccount } from '../../Models/WaAccount';
// import moment from 'moment';

const EditAccount = () => {
  registerLocale("es", es);
  // const wspAccount = waAccount.waAccount

  const { t } = useTranslation();
  const { userProfile } = useContext(DataContext);
  const [startDate, setStartDate] = useState(new Date());
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [statusOk, setStatusOk] = useState(true);
  const [waTableInfo, setWaTableInfo] = useState(null);
  const [test, setTest] = useState(null); // eslint-disable-line
  const [showCargarLogs, setShowCargarLogs] = useState(true);

  const [value, setValue] = useState(0);
  // let date = moment(editAccount.created_at.toDate()).format('L, h:mm a');
  // console.log(date)
  const min = 0;
  const max = 60;
  // if (editAccount !== null) {
  //   console.log("NEWeditAccount", editAccount)
  // }

  // const CuentaWsp = useSelector(state => state.stateWa)
  // const CuentaWsp = useSelector((state) => state.stateWa.estado)
  const CuentaWsp = useSelector(waAccountEdit);
  const aux = Object.keys(CuentaWsp);
  const dispatch = useDispatch();

  const editarNombre = async () => {
    setStatusOk(false);
    if (aux === 0) {
      return;
    }
    try {
      let newName = document.querySelector("#nombreEdit").value;
      let newEliminarMsg = parseInt(
        document.querySelector("#autoEliminarMensajesEdit").value
      );
      let newWebhook = document.querySelector("#webhookEdit").value;

      let result = await APICuentas.editarCuenta(
        CuentaWsp.id,
        newName,
        newEliminarMsg,
        newWebhook
      );
      result.status === 200 && toast.success(t("tostify.changesSaved"));

      setStatusOk(true);
    } catch (error) {}
  };
  // const accountRef = collection(db, "users", userProfile.uid, "accounts", editAccount.id);
  let unsubscribed = null;
  const getWaDataAccount = () => {
    unsubscribed = onSnapshot(
      doc(db, "users", userProfile.uid, "accounts", CuentaWsp.id),
      (doc) => {
        // console.log("Current data: ", doc.data());
        let waAccountChanges = doc.data();
        let jsonWa = new WaAccount(waAccountChanges);
        //console.log(waAccountChanges)
        //console.log(typeof waAccountChanges)
        dispatch(setCuentaWsp(jsonWa));
        // dispatch(setCuentaWsp(waAccountChanges))
        // setEditAccount(waAccountChanges);
      }
    );
  };
  useEffect(() => {
    getWaDataAccount();
    return unsubscribed;
  }, []);
  const closeEditAccount = () => {
    try {
      if (unsubscribed != null) {
        unsubscribed();
      }
      dispatch(closeCuentaWsp());
      // setEditAccount(null);
    } catch {}
  };

  const openDeleteDialog = () => {
    setOpenDialogDelete(true);
  };
  const deleteAccount = async () => {
    setOpenDialogDelete(false);

    let resultado = await APICuentas.borrarCuenta(CuentaWsp.id); // eslint-disable-line
    //console.log('delete account: ', resultado)

    dispatch(closeCuentaWsp());

    toast.warning(t("tostify.deletedAccount"));
  };

  const cargarLogs = async (id, fecha) => {
    let result = await APICuentas.getLogs();
    // console.log(result);

    setWaTableInfo(result);
    setStartDate(fecha);
    setShowCargarLogs(false);
    setTest(true);
  };
  const onChannelConectionReset = () => {
    console.log("hola");
  };
  const onReloadQR = () => {
    console.log("holae");
  };
  const onUnlinkLogout = () => {
    console.log("hola");
  };
  return (
    <>
      <LayoutCentered>
        <div className="content d-flex align-items-center justify-content-between position-sticky">
          <div>
            <h2 className="d-flex h1 m-3">
              {t("global.account")}
              <div className="d-flex flex-column ml-3">
                <Box>
                  <span>{CuentaWsp.name}</span>
                  <Chip
                    className="ml-5"
                    // label={t("editAccountDic.active")}
                    label={CuentaWsp.status}
                    // label={CuentaWsp? CuentaWsp.estado : "fallo"}
                    // color={grey[5 00]}
                    size="small"
                    sx={{
                      borderRadius: "5px",
                      height: "20px",
                      fontSize: "0.6125rem",
                      backgroundColor: "#dedede",
                    }}
                  />
                </Box>
                <span
                  style={{
                    fontSize: "15px",
                    color: "#8b8b8b",
                    marginTop: "-8px",
                  }}
                >
                  {CuentaWsp.id}
                </span>
              </div>
            </h2>
          </div>
          <CloseIcon
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={closeEditAccount}
          ></CloseIcon>
        </div>
        <Divider className="DividerTitleEditCuenta"></Divider>
        <Box
          className="overflow-auto api-custom-scroll"
          style={{ maxHeight: "calc(100vh - 230px)", paddingRight: "10px" }}
        >
          <div
            className="panel"
            style={{
              marginTop: 25,
            }}
          >
            <div className="panel-container show">
              <div className="panel-content">
                <Grid
                  container
                  className="px-6"
                  justifyContent="space-between"
                  // alignItems="center"
                >
                  <Grid item>
                    <div className="d-flex align-items-center">
                      <Typography component="h5" variant="h5">
                        {t("editAccountDic.state")}:
                      </Typography>
                      <Chip
                        className="ml-5"
                        label={CuentaWsp.wa_status}
                        // label={CuentaWsp? CuentaWsp.estado : "fallo"}

                        // color={grey[500]}
                        size="small"
                        sx={{ borderRadius: "5px", backgroundColor: "#dedede" }}
                      />
                    </div>
                    {CuentaWsp.wa_status === "UNPAIRED" && (
                      <div style={{ textAlign: "center" }}>
                        <img
                          src="qr-code test.png"
                          alt=""
                          style={{ width: "50%", height: "50%" }}
                        />
                      </div>
                    )}
                    {CuentaWsp.wa_status !== "UNPAIRED" && (
                      <div style={{ textAlign: "center" }}>
                        <div
                          style={{
                            backgroundColor: "red",
                            width: "50%",
                            height: "50%",
                          }}
                        ></div>
                        <img
                          src="qr-code test.png"
                          alt=""
                          style={{
                            width: "50%",
                            height: "50%",
                            opacity: "0.3",
                          }}
                        />
                        <Box className="reloadQrIcon" onClick={onReloadQR}>
                          <ReplayIcon fontSize="large"></ReplayIcon>
                          <Typography sx={{ fontSize: "0.8rem" }}>
                            {t("editAccountDic.reloadQR")}
                          </Typography>
                        </Box>
                      </div>
                    )}
                  </Grid>
                  <Grid item>
                    <Typography
                      component="h5"
                      variant="h5"
                      className="text-center"
                    >
                      {t("global.actions")}
                    </Typography>
                    <form className="form-group">
                      <div style={{ width: "100%", margin: "10px 0px" }}>
                        {/* Acciones */}
                        <Stack spacing={4} direction="column">
                          <Button
                            variant="contained"
                            size={"small"}
                            className="buttonsActionsWa"
                            // disabled={}
                            onClick={onChannelConectionReset}
                          >
                            {t("editAccountDic.channelConectionReset")}
                          </Button>
                          <Button
                            variant="contained"
                            size={"small"}
                            className="buttonsActionsWa"
                            disabled={CuentaWsp.wa_status === "UNPAIRED"}
                            onClick={onReloadQR}
                          >
                            {t("editAccountDic.reloadQR")}
                          </Button>
                          <Button
                            variant="contained"
                            size={"small"}
                            className="buttonsActionsWa"
                            disabled={
                              CuentaWsp.wa_status === "UNPAIRED_IDLE" ||
                              CuentaWsp.wa_status === "UNPAIRED"
                            }
                            onClick={onUnlinkLogout}
                          >
                            {t("editAccountDic.unlinkLogout")}
                          </Button>
                        </Stack>
                      </div>
                    </form>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div className="panel">
            <div className="panel-container show">
              <div className="panel-content">
                <Typography component="h5" variant="h5">
                  {t("global.account")}
                </Typography>
                <form className="form-group">
                  <TextField
                    fullWidth
                    id="nombreEdit"
                    label={t("global.name")}
                    defaultValue={CuentaWsp.name}
                    variant="outlined"
                    name={t("global.name")}
                    style={{ marginTop: 25 }}
                  />
                  <TextField
                    fullWidth
                    id="autoEliminarMensajesEdit"
                    label={t("editAccountDic.autoDeleteWaMessages")}
                    // defaultValue={value}
                    value={value}
                    variant="outlined"
                    name={t("editAccountDic.autoDeleteWaMessages")}
                    style={{ marginTop: 25 }}
                    type="number"
                    InputProps={{ inputProps: { min: min, max: max } }}
                    onChange={(e) => {
                      let val = e.target.value;
                      if (val > max) {
                        val = max;
                      }
                      if (val < min) {
                        val = min;
                      }
                      setValue(val);
                    }}
                  />
                  <TextField
                    fullWidth
                    id="webhookEdit"
                    label={"Webhook"}
                    defaultValue={CuentaWsp.webhook}
                    variant="outlined"
                    name="Webhook"
                    style={{ marginTop: 25 }}
                  />
                </form>
                <div style={{ textAlignLast: "end" }}>
                  {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={editarNombre}
                  >
                    {t("global.save")}
                  </Button> */}

                  <Button
                    size={"small"}
                    variant="contained"
                    onClick={editarNombre}
                    disabled={statusOk === false ? true : false}
                    startIcon={
                      statusOk === false ? (
                        <Spinner
                          style={{
                            height: "1.5rem",
                            width: "1.5rem",
                            fontSize: "12px",
                          }}
                          animation="border"
                        />
                      ) : null
                    }
                  >
                    {statusOk === false ? t("global.saving") : t("global.save")}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="panel"
            style={{
              marginTop: 25,
            }}
          >
            <div className="panel-container show">
              <div className="align-items-end d-flex justify-content-between panel-content">
                {/* <Button
                  variant="contained"
                  color="primary"
                  startIcon={<VisibilityIcon />}
                >
                  Ver Logs
                </Button> */}
                <Typography component="h5" variant="h5">
                  Logs
                </Typography>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => cargarLogs(0, date)}
                  locale="es"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              {showCargarLogs && (
                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => cargarLogs(0, startDate)}
                  >
                    {t("editAccountDic.loadLogs")}
                  </Button>
                </div>
              )}
              <div style={{ margin: 16 }}>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{ minWidth: "110px" }}
                          style={{ backgroundColor: "lightgray" }}
                        ></TableCell>
                        <TableCell
                          style={{ backgroundColor: "lightgray" }}
                          sx={{ minWidth: "200px" }}
                          size={"small"}
                        >
                          {t("editAccountDic.dateHour")}
                        </TableCell>
                        <TableCell
                          style={{ backgroundColor: "lightgray" }}
                          sx={{ width: "100%" }}
                        >
                          {t("global.detail")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {waTableInfo !== null && (
                      <TableBody>
                        {waTableInfo !== null &&
                          waTableInfo.map((row) => (
                            <TableRow
                              key={row.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell sx={{ width: "110px" }}>
                                <Typography
                                  sx={{ color: row.color, fontSize: "0.8rem" }}
                                >
                                  {row.Tipo}
                                </Typography>
                              </TableCell>
                              <TableCell scope="row" sx={{ minWidth: "200px" }}>
                                {row.Datetime}
                              </TableCell>
                              <TableCell sx={{ width: "100%" }}>
                                {row.Description}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
          <div
            className="panel"
            style={{
              marginTop: 25,
              marginBottom: 50,
            }}
          >
            <div className="panel-container show">
              <div className="d-flex flex-column panel-content">
                <div className="d-flex flex-column">
                  <span className="bolder font-weight-bold mb-1 text-uppercase">
                    {t("editAccountDic.deleteAccount")}
                  </span>
                  <span>{t("editAccountDic.deleteAccountMessage")}</span>
                </div>
                <div className="align-self-end mt-3">
                  <Button
                    onClick={openDeleteDialog}
                    variant="contained"
                    color="error"
                    startIcon={<DeleteIcon />}
                  >
                    {t("global.delete")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </LayoutCentered>
      <Dialog
        open={openDialogDelete}
        onClose={() => setOpenDialogDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("deleteAccountDialog.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("deleteAccountDialog.subtitle")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialogDelete(false)}>
            {t("global.cancel")}
          </Button>
          <Button color="error" onClick={deleteAccount} autoFocus>
            {t("global.delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditAccount;

import React from 'react';

import Main from './Main';
import {
  Main as MainSection,
} from './components';

const Pricing = () => {
  return (
    <Main>
      <MainSection />
    </Main>
  );
};

export default Pricing;

import React from "react";

const Spinner = ({ texto, style }) => {
  return (
    <>
      <div className="centered-element-spinner-panel" style={style}>
      <div className="spinner-border" role="status"></div>
      <div className="ml-25less mt-3 spinnerFont">{texto}</div>
      </div>
    </>
  );
}

export default Spinner;
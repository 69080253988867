import React, { Fragment } from "react";
import Iframe from "react-iframe";

const DocsIframe = () => {
  return (
    <Fragment>
      <Iframe
        url="https://docs.api-qr.com/"
        width="100%"
        height="100%"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"
        frameBorder={0}
      />
    </Fragment>
  );
};

export default DocsIframe;

/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your first name"),
  lastName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your last name"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),

  password: yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have at minimum length of 8"),
});

const Form = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };

  let history = useNavigate();
  const onSubmit = (values) => {
    // alert(JSON.stringify(values,null,   2))
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const auth = getAuth();
  const { t } = useTranslation();

  const registrar = React.useCallback(async () => {
    try {
      //console.log("registrado")
      const res = await createUserWithEmailAndPassword( // eslint-disable-line
        auth,
        formik.values.email,
        formik.values.password
        // eslint-disable-next-line react-hooks/exhaustive-deps

      );
      try {
        updateProfile(auth.currentUser, {
          displayName: (
            formik.values.firstName.trim() +
            " " +
            formik.values.lastName.trim()
          ).trim(),
        }).then(() => {
          // Profile updated!

        }).catch((error) => {
          // An error occurred
          // ...
          console.log("error", error);
        });;
      } catch (err) {
        console.log("err", err);
      }
      sendEmailVerification(auth.currentUser).then(() => {
        // Email verification sent!
        // ...
      });
      // history.push("/signin");
      //console.log(auth.currentUser)
      history("/", { replace: true })


      //console.log(res.user);
    } catch (error) {
      //console.log(error);
      if (error.code === "auth/invalid-email") {
        alert(t("login.errorMail"));
      }
      if (error.code === "auth/email-already-in-use") {
        alert("email registrado");
      }
      if (error.code === "auth/wrong-password") {
        alert(t("login.errorPassword"));
      }
    }
  }, [
    history,
    auth,
    formik.values.email,
    formik.values.password,
    formik.values.firstName,
    formik.values.lastName,
  ]);

  //     const res = await createUserWithEmailAndPassword(formik.values.email, formik.values.password);
  //     console.log(res.user);
  //     await db.collection('usuarios').doc(res.user.uid).set({
  //       fechaCreacion: Date.now(),
  //       displayName: res.user.displayName,
  //       photoURL: res.user.photoURL,
  //       email: res.user.email,
  //       uid: res.user.uid
  //   })
  //   history.push("/");

  //   } catch (error) {
  //     console.log(error)
  //     // setError(error.message)
  //     if(error.code === 'auth/email-already-in-use'){
  //         alert('Usuario ya registrado...')
  //         return
  //     }
  //     if(error.code === 'auth/invalid-email'){
  //         alert('Email no válido')
  //         return
  //     }
  //   }
  // }, [history, formik.values.email, formik.values.password]);

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"text.secondary"}
        >
          {t("register.signup")}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          {t("register.createAnAccount")}
        </Typography>
        <Typography color="text.secondary">
          {t("register.fillOutFormGetStarted")}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
      
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              {t("register.enterEmail")}
            </Typography>
            <TextField
              label="Email *"
              variant="outlined"
              name={"email"}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              {t("register.enterPassword")}
            </Typography>
            <TextField
              label={t("global.password")+"*"}
              variant="outlined"
              name={"password"}
              type={"password"}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              maxWidth={600}
              margin={"0 auto"}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={"subtitle2"}>
                  {t("register.alreadyAccount")}{" "}
                  <Link
                    component={"a"}
                    color={"primary"}
                    to={"/signin"}
                    href={"/signin"}
                    underline={"none"}
                  >
                    {t("global.login")+"."}
                  </Link>
                </Typography>
              </Box>
              <Button
                size={"large"}
                variant={"contained"}
                type={"submit"}
                onClick={registrar}          
              >
                {t("register.sign Up")}
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant={"subtitle2"}
              color={"text.secondary"}
              align={"center"}
            >
              {t("register.byClickingAgree")}{" "}
              <Link
                component={"a"}
                color={"primary"}
                href={'files/API-QR_TerminosyCondiciones.pdf'}
                underline={"none"}
                target="_blank"
                rel="noopener" 
              >
                {t("register.companyTermsConditions")}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;

import React from "react";
import NavMenu from "./NavMenu";
import EnlacesLogin from "./EnlacesLogin";
import "./MainLayout.css";

import PropTypes from "prop-types";
function MainLayout(props) {
  //console.log(props)
  return (
    <div className="page-wrapper">
      <div className="page-inner">
        <div className="page-sidebar sidebar-sticky">
          <NavMenu />
        </div>
        <div className="page-content-wrapper">
          <div className="page-header header-sticky">
            <EnlacesLogin authenticated={true} />
          </div>
          <div id="js-page-content" className="page-content">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}
MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;

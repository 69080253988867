import React from 'react';
import './ModalUsers.css'
import { Button, Modal } from 'react-bootstrap';
import { TextField} from "@material-ui/core";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';


toast.configure()

const ModalEditUsers = ({ user, setUser, onHandleSave, onHandleClose }) => {
  const { t } = useTranslation();

  // const { user, setUser, onHandleSave, onHandleClose } = props
  const handleChange = e => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value
    });
  }
  const onSave = () => {
    onHandleSave(user)
  }

  return (
    <>
      <Modal show={true} dialogClassName="modal-fullscr">
        <Modal.Body>
          <form className='form-group'>
            <TextField
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              id=""
              label={t("id")}
              name='id'
              variant="outlined"
              onChange={handleChange}
              value={user.id}
              className="mb-4"
            />
            <TextField
              fullWidth
              type="text"
              id="name"
              label={t("name")}
              variant="outlined"
              name='name'
              onChange={handleChange}
              value={user.name}
              className="mb-4"
            />
            <TextField
              fullWidth
              type='email'
              id="email"
              label={t("email")}
              variant="outlined"
              name='email'
              onChange={handleChange}
              value={user.email}
              className="mb-4"
            />
            <TextField
              fullWidth
              name='phone'
              id="phone"
              label={t("phone")}
              variant="outlined"
              onChange={handleChange}
              value={user.phone}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onHandleClose}>
            CANCELAR
          </Button>
          <Button type="submit" variant="primary" onClick={onSave}>
            EDITAR
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ModalEditUsers.propTypes = {
  user: PropTypes.node,
  setUser: PropTypes.node,
  onHandleSave: PropTypes.node,
  onHandleClose: PropTypes.func
}
;

export default ModalEditUsers;
import { configureStore } from '@reduxjs/toolkit'
import editWaAccountReducer from './features/WaAccountSlice'
// import { setupListeners } from '@reduxjs/toolkit/query'
export const store = configureStore({
  reducer: {
    AccountWa: editWaAccountReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false,
    })
})



import React,{ useState, useCallback, useEffect} from "react";
// import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CheckIcon from '@mui/icons-material/Check';
import {getAuth} from 'firebase/auth'
import { useTranslation } from "react-i18next";
import Spinner from "../../Spinner";



// { onClose, open }
const PopupUnverify = () => {
  const [buttonDisable, setButtonDisable] = useState(true)
  const currentUser = getAuth().currentUser //eslint-disable-line
  // const auth = getAuth()
  const checkEmailVerify = useCallback (() =>{
    getAuth().currentUser.reload()
    let emailVerified = getAuth() && getAuth().currentUser && getAuth().currentUser.emailVerified ? true : false;
    
    setButtonDisable(!emailVerified)
  },[getAuth().currentUser])
  useEffect(() => {
    setInterval(checkEmailVerify, 5000)
  }, [])

  const { t } = useTranslation();
  
  return (
    <Dialog
      // onClose={onClose}
      open={true}
      maxWidth={"sm"}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 4,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingY: { xs: 4, sm: 10 },
          paddingX: { xs: 4, sm: 12 },
          height: "500px",
          maxHeight: "500px",
        }}
      >
        <Typography
          variant={"h5"}
          fontWeight={700}
          align={"center"}
          marginBottom={4}
        >
          {t("popupUnverify.title")}
        </Typography>
        <Typography align={"center"}>{t("popupUnverify.subtitle")}</Typography>
        <Grid container spacing={2} sx={{ marginY: 4 }}>
          {buttonDisable === true && (
            <Spinner
              texto={t("popupUnverify.verifying")}
              style={{ top: "60%", left: "48%" }}
            ></Spinner>
          )}
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{ marginY: 4, position: "absolute", bottom: 0, right: "40px " }}
        >
          <Grid item xs={12} style={{ textAlign: "end" }}>
            <Button
              size={"large"}
              variant={"contained"}
              // type={"submit"}
              // fullWidth
              startIcon={<CheckIcon />}
              // disabled={verify}
              disabled={buttonDisable}
              // onClick={showUserDataPopup}
              component={"a"}
              href="/"
            >
              {t("popupUnverify.next")}
            </Button>
          </Grid>
        </Grid>
        {/* <Typography
          align={'center'}
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          // onClick={onClose}
        >
          cerrar sesion
        </Typography> */}
      </Box>
    </Dialog>
  );
};

// PopupBox.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
// };

export default PopupUnverify;

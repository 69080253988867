import React, {  useState,useCallback, useMemo} from 'react';
// import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
// import { NavItem } from 'src/layouts/Main/components/Sidebar/components/SidebarNav/components/NavItem/NavItem';

import { useTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const TopNav = () => {
  const { t, i18n } = useTranslation();
  let select = JSON.parse(localStorage.getItem('select'));
  const [value, setValue] = useState(select); 
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  useMemo(() => {
    // console.log(select);
    if (select === null) {
      changeLanguage('Es');
      setValue('espanol');
    } 
  }, []);
  const handleChangeLenguaje = useCallback((e) => {
    
    if (e.target.value === 'espanol') {
      changeLanguage('Es');
      setValue('espanol');
    } else {
      setValue('ingles');
      changeLanguage('En');
    }
    localStorage.setItem('select', JSON.stringify(e.target.value));
  }, []);

  return (
    <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
      <Box marginRight={{ xs: 1, sm: 2 }}>
        <FormControl variant='standard' sx={{ m: 1 }}>
          <Select autoWidth value={value} onChange={handleChangeLenguaje}>
            <MenuItem value="espanol">{t('global.spanish')}</MenuItem>
            <MenuItem value="ingles">{t('global.english')}</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

// TopNav.propTypes = {
//   colorInvert: PropTypes.bool,
// };

export default TopNav;
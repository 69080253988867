import React from "react";
import Router from "./router";
import DataProvider  from "./Context/contextApi";
import { BrowserRouter as BrowseRouter } from "react-router-dom";

// import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { store } from './ReduxToolkit/store'
import { Provider } from 'react-redux'

const App = () => {
 
  return (
    <>
      {
        <DataProvider>
          <Provider store={store}>
            <BrowseRouter>
              <Router />
            </BrowseRouter>
          </Provider>
        </DataProvider>
      }
    </>
  );
}



export default App;
// export default App;
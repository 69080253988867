import React, { Fragment } from 'react'
import PropTypes from 'prop-types';

const LayoutCentered = (props) => {
  return (
    <Fragment>
    <div className="border-faded border-0">
      <div className="page-content p-0" style={{minHeight: "unset"}}>
        <div className="d-flex justify-content-center row">
          <div className="col-xl-7 col-lg-10 col-md-10 col-sm-10">
              {props.children}
          </div>
        </div>
      </div>
    </div>
  </Fragment>
  )
}

LayoutCentered.propTypes = {
  children: PropTypes.node,
};

export default LayoutCentered